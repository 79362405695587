import Navbar from "../components/Navbar";
import "../styles/home.css";
import builtFromOdisha from "../assets/images/build-from-odisha.webp";
import builtForOdisha from "../assets/images/build-for-odisha.webp";
import info1 from "../assets/images/info-1.png";
import info2 from "../assets/images/info-2.png";
import info3 from "../assets/images/info-3.png";
import info4 from "../assets/images/info-4.png";
import Footer from "../components/Footer";
// import prize from "../assets/images/prizes.svg";
// import timeline from "../assets/images/timeline.png";
import hackathon from "../assets/images/hackathon.png";
// import hackathon2 from "../assets/images/hackathon2.png";
import tablet_hackathon from "../assets/images/tablet_carousel_hackathon.png";
// import tablet_hackathon2 from "../assets/images/tablet_carousal_hackathon2.png";
import mobile_hackathon from "../assets/images/mobile_carousal_hackathon.png";
// import mobile_hackathon2 from "../assets/images/mobile_carousel_hackathon.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import judge1 from "../assets/images/anand.png";
import judge2 from "../assets/images/priyadarsh.png";
import judge3 from "../assets/images/arka.png";
import prize1 from "../assets/images/1.png";
import prize2 from "../assets/images/2.png";
import prize3 from "../assets/images/3.png";
const Home = () => {
  const firstDay = [
    {
      name: "Registration",
      time: ":  8:00 AM - 10:00 AM",
      spl: false,
    },
    {
      name: "Inaguration",
      time: ":  10:00 AM - 11:00 AM",
      spl: false,
    },
    {
      name: "Hackathon start",
      time: ":  11:00 AM",
      spl: true,
    },
    {
      name: "Lunch",
      time: ":  1:30 PM - 2:30 PM",
      spl: false,
    },
    {
      name: "Event refreshments",
      time: ":  5:00 PM - 5:30 PM",
      spl: false,
    },
    {
      name: "Dinner",
      time: ":  8:00 PM - 9:00 PM",
      spl: false,
    },
  ];
  const secondDay = [
    {
      name: "Night snacks and beverages",
      time: ":  12:00 AM - 1:00 AM",
      spl: false,
    },
    {
      name: "Breakfast",
      time: ":  7:00 AM - 8:00 AM",
      spl: false,
    },
    {
      name: "Hackathon ends",
      time: ":  11:00 AM",
      spl: true,
    },
    {
      name: "Lunch",
      time: ":  12:00 PM - 1:00 PM",
      spl: false,
    },
    {
      name: "Reporting for jury presentation",
      time: ":  4:30 PM - 6:30 PM",
      spl: false,
    },
    {
      name: "Prize distribution",
      time: ":  6:30 PM - 7:00 PM",
      spl: false,
    },
  ];
  return (
    <>
      <div>
        <Navbar />
        <div className="desktop_carousel">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            <div>
              <img
                src={hackathon}
                style={{ height: "100vh", objectFit: "cover" }}
                alt="desktop1"
              />
            </div>
            {/* <div>
              <img
                src={hackathon2}
                style={{ height: "100vh", objectFit: "cover" }}
                alt="desktop2"
              />
            </div> */}
          </Carousel>
        </div>
        <div className="tablet_carousel">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            <div>
              <img
                src={tablet_hackathon}
                style={{ height: "100vh", objectFit: "cover" }}
                alt="tablet1"
              />
            </div>
            {/* <div>
              <img
                src={tablet_hackathon2}
                style={{ height: "100vh", objectFit: "cover" }}
                alt="tablet2"
              />
            </div> */}
          </Carousel>
        </div>
        <div className="mobile_carousel">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            <div>
              <img
                src={mobile_hackathon}
                style={{ height: "100vh", objectFit: "cover" }}
                alt="mobile1"
              />
            </div>
            {/* <div>
              <img
                src={mobile_hackathon2}
                style={{ height: "100vh", objectFit: "cover" }}
                alt="mobile2"
              />
            </div> */}
          </Carousel>
        </div>
      </div>

      {/* <div className="hero-container">
        <Navbar />
        <div className="center-element flex-direction-col hero-section-flex-container">
          <h1 className="italic home-title">JANTRAJUGA</h1>
          <h2 className="italic text-white font-40 tagline">
            THE UTKALA DIBASA HACKATHON
          </h2>
          <p className="mt-36 text-grey-500 font-18 date-time">
            2 - 3 APR | 8:00 - 14:30 (Next Day) IST
          </p>
          <p className=" text-grey-500 font-18 date-time text-center">
            @ Software Technology Park of India, Gothapatana, Bhubaneswar
          </p>
        </div>
      </div> */}

      <div className="second-section-container">
        <div className="flex align-start justify-between second-section">
          <div className="flex-1 pr-48 left_top">
            <h1 className="font-72 italic second-section-heading">
              GET TO KNOW THE EVENT
            </h1>
          </div>
          <div className="flex-1-2 mt-24 right_top">
            <h2 className="font-32 weight-600 mb-24 second-section-subheading">
              About the hackathon
            </h2>
            <p className="line-height-1_8 text-grey-700 font-16 text-justify">
              This year on the occasion of Utkala Dibasa we at Mool Innovation
              Labs in association with Reverie Language Technologies are
              organizing JantraJuga ‘22 Hackathon to celebrate the foundation of
              our state. Through this hackathon we are bringing together
              Odisha’s developer community to use technology to “Build For and
              From Odisha” on some of the most pressing issues prevalent in
              these tough times.
            </p>
            <p className="line-height-1_8 mt-24 text-grey-700 font-16 text-justify">
              {" "}
              It is a 24 hours physical hackathon focused on identifying the
              challenges surrounding various issues in different areas of
              financial inclusion, modernizing healthcare, language
              localization, accessible education, etc. and build innovative
              solutions through the application of information technology.
            </p>
            <h2 className="font-32 weight-600 mb-24 mt-24 second-section-subheading">
              Purpose
            </h2>
            <div className="flex align-center justify-start">
              <div className="line"></div>
              <h4 className="weight-500 italic line-height-1_8">
                Inspire action to convert ideas of Odisha’s youth into
                real-world impact.
              </h4>
            </div>
            <p className="line-height-1_8 text-grey-700 font-16 mt-24 text-justify">
              We want to connect with what Odisha’s student community care about
              and help empower them to use technology to take action on issues
              that will help create a positive impact in our society.
            </p>
            <h2 className="font-32 weight-600 mb-24 mt-24 second-section-subheading">
              Platforms to use:
            </h2>
            <p className="line-height-1_8 text-grey-700 font-16 mt-24">
              Coming soon
            </p>
          </div>
        </div>
      </div>
      {/* <div className="timeline-section-container">
        <h1 className="font-72 italic text-white" id="timeline-heading">
          EVENT TIMELINE
        </h1>
        <img src={timeline} alt="timeline" />
      </div> */}
      <div className="second-section-container bg-black">
        <h1 className="font-72 italic text-white second-section-heading">
          EVENT ITINERARIES
        </h1>
        <h2 className="event-date text-yellow weight-600 mt-96">
          2nd March, 2022
        </h2>

        <div className="event-content">
          {firstDay.map((event) => {
            return (
              <div
                className={`event-container ${
                  event.spl ? "bg-orange" : "bg-transparent"
                }`}
              >
                <div className="event-name">
                  <p className="text-white">{event.name}</p>
                </div>
                <div className="event-time">
                  <p className="text-white">{event.time}</p>
                </div>
              </div>
            );
          })}
        </div>
        <h2 className="event-date text-yellow weight-600 mt-96">
          3rd March, 2022
        </h2>
        <div className="event-content">
          {secondDay.map((event) => {
            return (
              <div
                className={`event-container ${
                  event.spl ? "bg-orange" : "bg-transparent"
                }`}
              >
                <div className="event-name">
                  <p className="text-white">{event.name}</p>
                </div>
                <div className="event-time">
                  <p className="text-white">{event.time}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="third-section-container">
        <h1 className="font-72 italic text-white theme_heading">THEME</h1>
        <p className="line-height-1_8 text-white font-16 mt-16 theme_tagline">
          There are 2 broad themes for which participants can propose and build
          for:
        </p>
        <div className="flex align-start justify-between mt-48 px-64 third-section-flex">
          <div className="left">
            <img src={builtForOdisha} alt="" className="width-23" />
            <h2 className="text-white weight-600 font-28">Build For Odisha</h2>
            <p className="text-white font-14 line-height-1_8 width-60 mt-16 text-justify">
              To target, address and solve issues specific to the people of
              Odisha.
            </p>
          </div>
          <div className="right">
            <img src={builtFromOdisha} alt="" className="width-23" />
            <h2 className="text-white weight-600 font-28">Build From Odisha</h2>
            <p className="text-white font-14 line-height-1_8 width-60 mt-16">
              To encourage participants to build on ideas that address issues of
              the world.
            </p>
          </div>
        </div>
      </div>
      <div className="fourth-section-container">
        <div className="flex align-center justify-center flex-direction-col">
          <h1 className="font-68 italic fourth-section-heading">
            THINGS TO KEEP IN MIND
          </h1>
          <div className="flex align-center justify-between width-full mt-48 fourth-section-reverse-flex-container">
            <div className="">
              <h2 className="font-28 weight-600 mb-24 mt-24 width-30 sub-section-heading">
                Eligibility and Participation:
              </h2>
              <ul className="pl-16 width-80 text-grey-700 line-height-1_8">
                <li>
                  This hackathon is open to all working professionals and
                  students irrespective of streams schools and colleges (Since
                  it is a physical hackathon the participants need to be
                  physically present at the event venue on the day of the
                  hackathon)
                </li>
                <li>Each team will have 3 members and 1 mentor (optional).</li>
                <li>
                  The students will have the freedom to choose their team
                  irrespective of college.
                </li>
                <li>
                  The hackathon time will be of 24 hours of duration
                  (continuous).
                </li>
                <li>
                  The participants will have the freedom to choose their team
                  and Mentor irrespective of college.
                </li>
              </ul>
            </div>
            <div className="">
              <img src={info1} alt="" className="width-30r" />
            </div>
          </div>
          <div className="flex align-center justify-between width-full mt-96 fourth-section-flex-container">
            <div className="flex-1-2">
              <img src={info2} alt="" className="width-30r" />
            </div>
            <div className="flex-1">
              <h2 className="font-28 weight-600 mb-24 mt-24 width-40 sub-section-heading">
                Stages of the event:
              </h2>
              <ul className="pl-16  text-grey-700 line-height-1_8 text-justify">
                <li>
                  Registrations begins on{" "}
                  <span className="text-grey-900">19th of March ’22</span> and
                  closes on{" "}
                  <span className="text-grey-900">27th March ’22.</span>
                </li>
                <li>
                  Problem statements will be released on{" "}
                  <span className="text-grey-900">25th March ’22.</span> This
                  along with a proposal submission link will be shared to
                  registered teams via email.
                </li>
                <li>
                  Teams are needed to select a problem statement & submit a
                  proposal in the form of{" "}
                  <span className="text-grey-900">
                    a video pitch (2-3 minutes) or written document with
                    infographics (400 words)
                  </span>{" "}
                  describing their solution for screening.
                </li>
                <li>
                  All submitted propositions will be screened on the basis of{" "}
                  <span className="text-grey-900">
                    Technical Understanding, Conceptual Soundness & Impression
                    of the Proposal.
                  </span>
                </li>
                <li>
                  Teams need to submit their proposals before{" "}
                  <span className="text-grey-900">
                    11:59 pm on 28th Mar ’22.
                  </span>
                </li>
                <li>
                  The screening results will be declared on{" "}
                  <span className="text-grey-900">30th March ’22</span> and
                  selected participants will be communicated via email.
                </li>
                <li>
                  Selected teams need to be present physically at the event
                  venue on the days of the Hackathon{" "}
                  <span className="text-grey-900">(2nd & 3rd April).</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex align-center justify-between width-full mt-96 fourth-section-reverse-flex-container">
            <div className="flex-1">
              <h2 className="font-28 weight-600 mb-24 mt-24 width-40 sub-section-heading">
                Final Product
              </h2>
              <p className="text-grey-700 line-height-1_8 text-justify">
                Participants are expected to come up with a working prototype of
                the solution they are proposing on the day of the event. Proof
                of concept or just PowerPoint presentation will not do.
              </p>
            </div>
            <div className="flex-1-2">
              <img src={info3} alt="" className="width-30r float-right" />
            </div>
          </div>
          <div className="flex align-center justify-between width-full mt-96 fourth-section-flex-container">
            <div className="flex-1-2">
              <img src={info4} alt="" className="width-30r" />
            </div>
            <div className="flex-1">
              <h2 className="font-28 weight-600 mb-24 mt-24 width-60 sub-section-heading">
                Judging Criteria
              </h2>
              <p>All hacks will be judged based on the following criteria:</p>
              <ul className="pl-16  text-grey-700 line-height-1_8">
                <li>Complexity of the problem being solved.</li>
                <li>Impact of the hack</li>
                <li>Innovative quotient / uniqueness</li>
                <li>Completeness and Presentation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="fifth-section-container">
        <h1 className="font-72 italic text-black fifth-section-heading mb-48">
          JUDGES
        </h1>
        <div
          className="flex justify-center flex-wrap fifth-flex-container"
          style={{ gap: "2rem" }}
        >
          <div className="judge-container">
            <div
              className="judge-img-container"
              style={{ backgroundColor: "#FEBA01" }}
            >
              <img src={judge1} alt="judge" />
            </div>
            <h3>Anand Virani</h3>
            <p>Co-founder and CEO at Inventa</p>
          </div>
          <div className="judge-container">
            <div
              className="judge-img-container"
              style={{ backgroundColor: "#FE7601" }}
            >
              <img src={judge2} alt="judge" />
            </div>
            <h3>Priyadarshi Mohapatra</h3>
            <p>Founder & CEO, Curebay</p>
          </div>
          <div className="judge-container">
            <div
              className="judge-img-container"
              style={{ backgroundColor: "#6FB3F7" }}
            >
              <img src={judge3} alt="judge" />
            </div>
            <h3>Arka Jena</h3>
            <p>General Manager: Head Business Function</p>
            <p>and Support Services, BFSI CBO at TCS</p>
          </div>
        </div>
      </div>
      <div className="sixth-section-container">
        <h1 className="font-72 italic text-white sixth-section-heading mb-48">
          PRIZES
        </h1>
        <div className="card-container flex align-center prize-flex-container">
          <div className="medium-card flex align-center justify-center flex-direction-col">
            <div className="img-container">
              <img src={prize2} alt="" />
            </div>
            <p>Runners up recieves</p>
            <h3>1.5 Lakh</h3>
          </div>
          <div className="big-card flex align-center justify-center flex-direction-col">
            <div className="img-container">
              <img src={prize1} alt="" />
            </div>
            <p>Winner recieves</p>
            <h3>2.5 Lakh</h3>
          </div>
          <div className="small-card flex align-center justify-center flex-direction-col ">
            <div className="img-container">
              <img src={prize3} alt="" />
            </div>
            <p>2nd runnners up recieves</p>
            <h3>1 Lakh</h3>
          </div>
        </div>
        <div className="mobile-card-container">
          <div className="card flex align-center justify-center flex-direction-col">
            <div className="img-container">
              <img src={prize1} alt="" />
            </div>
            <p>Winner recieves</p>
            <h3>2.5 Lakh</h3>
          </div>
          <div className="card flex align-center justify-center flex-direction-col ">
            <div className="img-container">
              <img src={prize2} alt="" />
            </div>
            <p>Runners up recieves</p>
            <h3>1.5 Lakh</h3>
          </div>
          <div className="card flex align-center justify-center flex-direction-col">
            <div className="img-container">
              <img src={prize3} alt="" />
            </div>
            <p>2nd runners up recieves</p>
            <h3>1 Lakh</h3>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
