import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import transport from "../assets/images/transport.svg"
import storage from "../assets/images/storage.svg"
// import shield from "../assets/images/shield.svg"
// import people from "../assets/images/people.svg"
// import cart from "../assets/images/cart.svg"
import code from "../assets/images/code.svg"
// import ball from "../assets/images/ball.svg"
import cube from "../assets/images/cube.svg"
import tablet from "../assets/images/tablet.svg"
import buildForOdisha from "../assets/images/build-for-odisha.webp"
import buildFromOdisha from "../assets/images/build-from-odisha.webp"

import "../styles/problem.css"

const ProblemStatement = () => {

    const problemStatements = [
        {
            logo: transport,
            domain: "Citizen Engagement",
            ps: "There are many who think about problems of their cities, towns and villages, but do not have an avenue or oppurtunity or support of other like her/him. We can all be in silos and think about problems, or come together to share problems we can solve.",
            motivation: {
                1: "Siddhant sees a barren patch of land near his house, which is being used as a dumping yard. He does not like to see it, and wants to do something about it.",
                2: "Suman lives nearby, and feels the same, he thinks this patch can be planted with fruit bearing trees",
                3: "Both do not know each other, but feel individually hope there would be more who can help them solve this problem apparent in their city.",
                4: "These people can be brought together to discuss vote and solve problems like this, couldnt they?"
            },
            theme: buildForOdisha
        },
        {
            logo: storage,
            domain: "A better file storage system",
            ps: "In a world where everything is smart, we keep storing the information in the conventional way, every time we need to access that information SBI’s lunch break would be an absolute necessity. It is too time consuming to find that  single piece of information over millions of records at the same time we need to store millions of records. So doesn’t that create a need for a smart file storage system? \n Also there is a need to ensure that these records are stored securely. Blockchain based storage is a secured way of saving data in a decentralized network. Encrypting the files and distributing them across the decentralized network makes it harder for hackers to access the data.",
            motivation: {
                1: "Priyajit is an IT professional going for a check up to a hospital carrying piles of prescriptions and documents. While driving, on the way he starts wondering if there was a way he could store all this information somewhere and just had to provide some sort of identification to that storage and he would be able to access everything.",
                2: "Being an IT guy his curiosity kicks in and he starts asking questions. Will it be secure to store this information digitally? Even if we store these things digitally, how to make sure they are not tampered with? Who is going to monitor that? These are important documents, we can not let a single organization handle such sensitive information. So we need a secure and decentralised storage system.",
                3: "Also, currently our data storage systems are replicated storage systems. No matter number of backups, there is always a risk of data loss. A distributed storage system would solve this issue completely.",
                4: "Priyajit found a solution to secured and decentralised storage but then he  wondered how many records he could store there? It wasn’t not scalable. So, he needs a secure, decentralised and scalable solution.",
                5: "Priyajit after going through a lot of IT jargons, finally found the solution. But he has not started implementing it yet. Can you help Priyajit implement it?",
                6: "Priyajit wants to store his land records and love letters there too."
            },
            theme: buildFromOdisha
        },
        {
            logo: tablet,
            domain: "Course Management System",
            ps: "Amidst the COVID-19 pandemic, classes got converted to Google Meet, assignments got converted to Photos and last minute notes got converted to watching lecture recordings in 200x. While this pandemic brought digital eduction to society’s forefront, the possibilities now are very limited. Students especially in higher institutions, want a system where they can bunk classes and go watch a movie because at the end of the day they can watch the recording. Some teachers might even want a similar system where they can record their sessions at a convenient time and make the course content available systematically. If both students and teachers agree, why not build a system to help them out?",
            motivation: {
                1: "Jyoti retired from IT and joined as a lecturer in XYZ Institute of Technology. As a new teacher it seems his students don’t like him. He wishes if there was a way he could just upload his lectures somewhere and let the students ask him doubts once they have seen the recording. He should be able to figure out if the student asking the question has watched the recording because he wants to avoid students asking him doubts without having any context.",
                2: "Some of the students are mischievous and use fake names to abuse the him on chat when he’s teaching on Zoom. He wants a censored medium where he can communicate with students and where students can’t use fake names to abuse him. Jyoti is sensitive to abusive texts, it affects his confidence. He wants them to be filtered out.",
                3: "Whenever he gives any assignment somehow there will be this one student who will turn up after 100 years and say he was not aware of the deadline. Jyoti doesn’t want these scenarios, he wants a way where he can publish the assignments and set a deadline. Students get notified and upload their assignments there.",
                4: "Jyoti is facing many such problems. Can we help Jyoti?",
                5: "Also we don’t want his students to think we are biassed towards Jyoti, we can also take a look at the problems faced by his students and help them figure it out."
            },
            theme: buildFromOdisha
        },
        {
            logo: cube,
            domain: "Goods and Services Management System",
            ps: "In the current digital era there is huge a gap between a service provider and a service receiver. Currently there is no digital platform in Odisha that helps map inter-city/inter-state demand-supply networks. Looking at these scenarios of goods and service management there can be a central platform which will be solving all the large scale demand-supply problems and make the whole processes a lot more efficient.",
            motivation: {
                1: "Bhupen has a goods store in ‘Nayapalli’ and he has a customer base in the nearby society. Only Bhupen’s store has a product which is in demand all over Bhubaneswar. Abhishek as a buyer (can be another vendor or a person) from ‘Rasulgarh’ who needs the product that Bhupen has in his store. Abhishek should be able to find that the product is available at Abhishek’s store and can get  the service accordingly.",
                2: "Sanskar has a warehouse in ‘Koraput’ for storing fodders. Sanskar’s business provides services to the vendors of ‘Koraput’. But during this Covid pandemic there was a decrease in demands of fodders in ‘Koraput’. Ramakant as a fodder service provider from ‘Ganjam’ has a shortage of stocks of fodder. Can you help Ramakant?",
                3: "In the Covid-19 pandemic, there was an increase in the need of goods and products across all Odisha. The Government of Odisha should be able to list all the requirements in a place where the vendors who have the capability and stocks to fulfil it can interact and provide the service. Can you help the Government Of Odisha?",
            },
            theme: buildForOdisha
        },
     
        {
            logo: code,
            domain: "Promotion of Odia language in digital medium.",
            ps: "If we look at the internet today, most of the content is in English. If we take our Odia language, there are a lot of people who write in Odia. However, their writings are mostly limited to either local magazines, books, newspaper, their diaries or forwarded whatsapp messages. Starting from Odia writing, to odia readership if one sits down to ask why is this the case, you can easily come up with many challenges to solve. So why not look at these issues critically and solve what all we can?",
            motivation: {
                1: "Supriti has an MA in Odia, who teaches Odia language at school and in her free time she writes some really beautiful Odia poems in her diary. Sometimes she shares some of her work in her whatsapp status to which some of her friends show appreciations. But she is unable to should start a blog page for post her poetry. She would like to present her poetry with the style and beauty it deserves. But, she doesn't find the fonts that she can use. She is not sure if anyone can find her poems through internet search even if she writes about the mood and themes in Odia. Would enough people read it?",
                2: "Shuvam is a college student passionate about technology but he’s not that good in English. Shuvam wants to learn coding but he finds all the programming languages are in English. Having studied in the state medium, he wishes if he could code in Odia. Atleast that way he’d have to learn only one language - the coding language. Can we help Shuvam  learn coding without having to learn English?",
                3: "While OCR technology for the English language has matured quite a bit, the same is not the case for the Odia language. There are a lot of official government documents, old books, etc written in Odia language, which need to be parsed to create digital documents. Can we build a system which accurately parses these documents with a lower margin of error such that digital searchable versions can be created?"
            },
            theme: buildForOdisha
        }
    ]

    return (
        <div className="problem-container">
            <Navbar />
            <div className="problem-statement-flex-container">
                <h1 className="font-72 italic problem-section-heading">
                    Problem Statements
                </h1>
                {/* <p className="note">Note: Teams need to focus one area ,they can choose one or multiple motivations under each area.</p> */}
                <div className="info-container">
                    <h2>Instructions</h2>
                    <ul>
                        <li>Teams need to select and focus only one problem statement domain.</li>
                        <li>Teams can choose one or multiple motivations under each area.</li>
                        <li>Deadline for submission of Proposals: 28th March 2022, 11:59 pm.</li>
                        <li>
                            To submit your proposal <a style={{textDecoration: "underline", color: "orange"}} href="https://bit.ly/proposal_JJ22" rel="noreferrer">click here</a>
                        </li>
                    </ul>


                    <h2>Proposal Format</h2>

                    <article>
                        <b>Title of the proposal:</b> Mention a clear title for your proposal.
                        <br />
                        <b>Motivation:</b> Why did you choose this problem statement?
                        <br />
                        <b>Teams can come up with motivations other than the one’s stated below, however it should fall under the given problem statements only</b>
                        <br />
                        <b>Problem Statement & Solution</b>: Concisely in bullet points mention how will you be approaching the problem statements and provide a step by step solution for the same.
                        <br />
                        <b>Technology Used:</b> Describe which technology platforms you would be using to build the proposed solution.
                        <br />
                        <b>Future Scope:</b> Present a well articulated plan for how do you plan to take forward your solution and actually build a fully working marketable product/tool/service.
                        <br />
                    </article>

                    <p className="end-note">
                        <b>Note:</b> The format remains the same for video proposals. Keep the content crisp and to the point. The maximum file size for the proposal is <b>100MB</b>.
                    </p>

                </div>
                {problemStatements.map((pb, index) => {
                    return (<div className="row">
                        <div className="col">
                            <div className="tabs">
                                <div className="tab">
                                    <input type="checkbox" id={`chck${index}`} />
                                    <label className="tab-label" for={`chck${index}`}>
                                        <div className="flex align-center justify-center">
                                            <div className="img-container">
                                                <img src={pb["logo"]} alt="" />
                                            </div>
                                            <p>{pb["domain"]}</p>
                                        </div>
                                    </label>
                                    <div className="tab-content">
                                        <div className="content">
                                            <h1 className="content-tab-heading">Problem Statement:</h1>
                                            <p className="content-problem-statement">{pb["ps"]}</p>
                                            <h1 className="content-tab-heading" style={{ marginTop: "1rem" }}>Motivation:</h1>
                                            {(Object.values(pb["motivation"]).map((e) => {
                                                return (<ul className="content-motivation-list">
                                                    <li className="mb-16">{e}</li>
                                                </ul>)
                                            }))}
                                            <h1 className="content-tab-heading">Theme:</h1>
                                            <img src={pb["theme"]} alt={pb["theme"]} className="build-for" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                })}
            </div>
            <Footer />
        </div>
    )
}

export default ProblemStatement