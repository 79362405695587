const Button = ({ buttonText, margin, backgroundColor, padding, borderRadius, textColor, fontSize }) => {
    const style = {
        margin: margin ? `${margin[0]}px ${margin[1]}px` : "[0, 0]",
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
        padding: padding ? `${padding[0]}px ${padding[1]}px` : "[0,0]",
        borderRadius: borderRadius ? borderRadius : 0,
        color: textColor ? textColor : "black",
        fontSize: fontSize ? fontSize : "1rem",
        outline: "none",
        border: "none",
        cursor: "pointer"
    }
    return (
        <>
            <button style={style}>
                {buttonText}
            </button>
        </>
    )
}

export default Button