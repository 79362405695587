import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import "../styles/faq.css"

const faqsList = [
    {
        q: "1. Do I need to pay any money to register for the Hackathon?",
        ans: "Ans : No. You do not have to pay anything to anyone to register yourself."
    },
    {
        q: "2. Do I need to have any specific qualifications to be a participant in the Hackathon?",
        ans: "Ans : If you love to code, you are more than welcome to participate in the Hackathon.",
    },
    {
        q: "3. Do we need to have the entire idea fully working?",
        ans: "Ans : The entire idea need not be fully implemented however, the submission should be functional so that it can be reviewed by the judges.",
    },
    {
        q: "4. I am a beginner. Am I eligible to participate?",
        ans: "Ans : Yes ofcourse, if you are a beginner its the best way to start your hacakthon journey and we would be very much pleased to have you.We would love to have your participation.",
    },
    {
        q: "5. I just graduated, can I still come to an event?",
        ans: "Ans : Yes.All school students and college students including the students graduating this year are invited.Even working professionals and people who don't have any formal education are welcomed!",
    },
    {
        q: "6. How is the environment? Will your environment support any language? Will you provide any IDE and DB for us to work on ideas?",
        ans: "Ans : You have to develop the entire software application on your local system and share the git repository format along with instructions to run the application and source code.",
    },
    {
        q: "7. Does one have to be available for the entire duration of the Hackathon?",
        ans: "Ans : Yes, you need to be present at the event location(offline) for the entire duration of the event.",
    },
    {
        q: "8. Since there is no specific technology mentioned, are there any restrictions on using a number of pre-built libraries?",
        ans: "Ans : You have to develop the entire software application on your local system and share the git repository format along with instructions to run the application and source code.",
    },
    {
        q: "9. Are we allowed to build on past projects/submit old projects?",
        ans: "Ans: Yes, You can bring your old projects, but you will be judged on the features you add during the hackathon.",
    },
    // {
    //     q: "10. Do I need to give a demo for the product that I have built?",
    //     ans: "Ans : If you want you can submit a small presentation or video that demos your submission, however, it's not mandatory, and only good to have. In case you are one of the winners, you might be invited to demo your application at a physical event, details of which will be shared with sufficient advance notice.",
    // },
    // {
    //     q: "11. A fully developed application need to have a solid backend for data storage like SQL, SharePoint etc. since its online, is it accepted to show only the prototype of it?",
    //     ans: "Ans : Yes it's absolutely fine to submit just the prototype. If you use any database like MySQL or PostgresSQL you can also submit a database dump along with your submission. However, you are allowed to submit just the prototype.",
    // },
    {
        q: "10. Who will own the IP(Intellectual Property) Rights to the product that I have built?",
        ans: "Ans : The developer / developers of the web / mobile application will have all rights and own the IP of the product.However, all code needs to be in the public domain(open source) so that it can be evaluated by the judges.",
    },
    {
        q: "11. Will accommodation and travel be provided?",
        ans: "Ans : No, the accommodation will not be provided at premises as it’s a 24 hrs Hackathon and participants need to arrange their accommodation pre and post Hackathon.During the hackathon participants will be provided with food and beverages. Get your sleeping bags if you must rest in the midst of the hackathon."
    },
]

const FAQ = () => {

    return (
        <div className="faq-container">
            <Navbar />
            <h1 className='faq-heading'>FREQUENTLY ASKED QUESTIONS</h1>
            {faqsList.map((faq, index) => {
                return (<div className="row">
                    <div className="col">
                        <div className="tabs">
                            <div className="tab">
                                <input type="checkbox" id={`chck${index}`} />
                                <label className="tab-label" for={`chck${index}`}>{faq["q"]}</label>
                                <div className="tab-content">
                                    {faq["ans"]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            })}
            <Footer />
        </div>
    )
}

export default FAQ