import mool from "../assets/images/mool.png"
import reverie from "../assets/images/reverie.svg"
// import stpi from "../assets/images/STPI.svg"
import zairza from "../assets/images/zairza.svg"
import "../styles/footer.css"

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="flex align-start justify-between footer-flex-container">
                {/* <div className="mool">
                    <p className="text-grey-700">Venue Partner</p>
                    <div class="img-container"><img src={stpi} alt="" className="width-10 height-10 mt-20" /></div>
                </div> */}
                <div className="reverie">
                    <p className="text-grey-700">Organised by</p>
                    <div class="img-container"><img src={mool} alt="" className="width-10 mt-20" /></div>
                </div>
                <div className="reverie">
                    <p className="text-grey-700">In association with</p>
                    <div class="img-container"><img src={reverie} alt="" className="width-10 mt-20" /></div>
                </div>
                <div className="reverie">
                    <p className="text-grey-700">Channel Partner</p>
                    <div class="img-container"><img src={zairza} alt="" className="width-10 mt-20" /></div>
                </div>
            </div>
            <div className="grey-line"></div>
            <p className="text-grey-700  ending-tag">Made with ❤️ and 👐 by Mool team </p>
        </div>
    )
}

export default Footer