import React, { useEffect, useState } from 'react'
import Button from './Button'
import menu from "../assets/images/ham.svg"
import cross from "../assets/images/cross.svg"
import { Link } from 'react-router-dom'
import "../styles/navbar.css"

const Navbar = () => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);


    const handleScroll = () => {
        const show = window.scrollY > 75;
        var nav = document.getElementsByClassName("nav-container")[0];
        var btnCntr = document.getElementsByClassName("button-container")[0];
        if (window.innerWidth > 1023) {
            if (show) {
                nav.style.backgroundColor = "rgba(0,0,0,0.4)";
                nav.style.padding = "20px 56px";
                btnCntr.style.padding = "0"
            } else {
                nav.style.backgroundColor = "transparent";
                nav.style.padding = "0 56px";
                btnCntr.style.padding = "48px 0 16px 0"
            }
        }
    };
    return (
        <div className='flex align-center justify-end nav-container'>
            <div className={"button-container pt-48 pb-16"}>
                <Link to={"/"}><Button buttonText={"Home"} margin={[0, 24]} textColor={"white"} fontSize={"1.05rem"} /></Link>
                <Link to={"/problem-statement"}><Button buttonText={"Problem Statements"} margin={[0, 24]} textColor={"white"} fontSize={"1.05rem"} /></Link>
                <Link to={"/faq"}><Button buttonText={"FAQ"} margin={[0, 24]} textColor={"white"} fontSize={"1.05rem"} /></Link>
                {/* <Link to={"mailto:help@jantrajuga.com"}><Button buttonText={"Contact Us"} margin={[0, 24]} textColor={"white"} fontSize={"1.05rem"} /></Link> */}
                <a href="mailto:help@jantrajuga.com" style={{ color: "white", fontSize: "1.05rem", margin: "0 24px", textDecoration: "none" }}>Contact Us</a>
                <a style={{ backgroundColor: "#F27C38", padding: "15px 24px", borderRadius: 25, color: "#fff", margin: "0 1.5rem" }} href="https://bit.ly/spot_registration" target="_blank" className="link" rel="noreferrer">Register Now</a>
                {/* <Button buttonText={"Register Now"} margin={[0, 32]} backgroundColor={"#F27C38"} padding={[16, 24]} borderRadius={"30px"} textColor={"white"} /> */}
            </div>
            {(window.innerWidth <= 768) && <>
                {!visible && <button className='menu-button' onClick={() => { setVisible(!visible) }}>
                    <img src={menu} alt="menu-btn" />
                </button>}
                {visible && <div className='menu-container'>
                    <img src={cross} alt="" style={{ width: "2rem" }} className="cross" onClick={() => { setVisible(!visible) }} />
                    <ul style={{ listStyle: "none", color: "white", fontSize: "0.8rem", marginTop: "3rem" }}>
                        <Link to={"/"} className="link">
                            <li className='menu-item'>Home</li>
                        </Link>
                        <Link to={"/problem-statement"} className="link">
                            <li className='menu-item'>Problem Statement</li>
                        </Link>
                        <Link to={"/faq"} className="link">
                            <li className='menu-item'>FAQ</li>
                        </Link>
                        <a href={"mailto:help@jantrajuga.com"} className="link">
                            <li className='menu-item'>Contact Us</li>
                        </a>
                        <a href="https://forms.gle/EaWonnoVpRccNajV9" target="_blank" className="link" rel="noreferrer">
                            <li className='menu-item'>Spot registrations would begin soon</li>
                        </a>
                    </ul>
                </div>}
            </>}

        </div>

    )
}

export default Navbar