import Home from "./pages/Home";
import "./styles/general.css"
import "./App.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import ProblemStatement from "./pages/ProblemStatement";
import FAQ from "./pages/FAQ";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Switch>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/problem-statement">
              <ProblemStatement />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Switch>
      </Router>
    </>
  );
}

export default App;
